import React from 'react';
import cn from 'classnames'

export const GenericList = ({
  listData,
  className,
}) => (
  <ul className={cn('list', className)}>
    {
      listData.map((item, index) => (
        <li className={cn('list--item')} key={index}>{item}</li>
      ))
    }
  </ul>
);

export const HeadersList = ({
  listData,
  className,
}) => (
  <dl className={cn('list', 'list--labels', className)}>
    {
      listData.map((item, index) => (
        <>
          <dt key={`${index}-title`} className={'list--labels--label'}>{item.header}</dt>
          <dd key={`${index}-item`} className={'list--labels--item'}>{item.item}</dd>
        </>
      ))
    }
  </dl>
);

export const GroupedList = ({
  groups,
  className,
  groupClassName
}) => {
  return(
  <ul className={cn('list','list--grouped', className)}>
    {
      groups.map((group, index) => (
        <li key={index} className={cn('list--group', groupClassName)}>
          <strong className={'list--group--label'}>{group.label}</strong>
          {group.list}
        </li>
      ))
    }
  </ul>
)};
