import React from 'react';
import {
  graphql,
  Link,
} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { GenericList } from '../components/lists';

const DocsPage = ({data}) => (
  <Layout>
    <SEO title="Documents" />
    <article>
      <h1>Documents</h1>
      <p>We're in the process of auditing and migrating the documents archive and <Link to="/photos">photo galleries</Link>. Please stay tuned for updates.</p>
      <h2>General Resources</h2>
      <GenericList listData={
        [
          <a target="_blank" rel="nofollow noreferrer" href={data.bylaws.publicURL}>EZAA Bylaws (Last Amended February 14, 2009)</a>,
          <a target="_blank" rel="nofollow noreferrer" href={data.bylaws_amendments.publicURL}>Proposed Bylaws Amendments (Drafted June 16, 2022)</a>,
        ]
      } />
      <h2>Meeting Minutes</h2>
      <GenericList listData={
        data.minutes.edges.map((item) => (
          <Link to={item.node.fields.slug}>{item.node.frontmatter.title}</Link>
        ))
      } />
    </article>
{ /*
    <GenericList listData={
      data.allFile.edges.map((file) => file.node.name)
    } />
*/ }
  </Layout>
);

export const query = graphql `query {
  allFile(
    filter: {
      sourceInstanceName: {eq: "docs"}
      extension: {nin: ["png", "gif"]}
    }
    sort: {order: ASC, fields: name}
  ) {
    edges {
      node {
        name
        publicURL
      }
    }
  }
  bylaws: file(name: {eq: "EZAA_Bylaws"}) {
    publicURL
  }
  bylaws_amendments: file(name: {eq: "proposed_bylaw_amendments_10-22"}) {
    publicURL
  }
  minutes: allMarkdownRemark(
    filter: {fields: {slug: {regex: "/minutes/"}}}
    sort: {order: DESC, fields: frontmatter___date}
  ) {
    edges {
      node {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
}`; 

export default DocsPage
